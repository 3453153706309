import { Component, OnInit,Inject, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { StudentService } from '../../../student/services/student.service';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-why-upgrade-plan',
  templateUrl: './why-upgrade-plan.component.html',
  styleUrls: ['./why-upgrade-plan.component.scss']
})
export class WhyUpgradePlanComponent implements OnInit {
  title: string;
  message: string;
  lableName: string;
  collegeName: any;
  shortName: any;
  tabArr: any[];
  public isSmallDevice: boolean = false;
  public isLargeDevice: boolean = false;
  selectedIndex : number = 0;
  activated = "-activated"
  activatedd = "-activatedd"
  videoSource = "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4";

  constructor(private spinner: NgxSpinnerService,public breakpointObserver: BreakpointObserver, private router: Router,private studentService: StudentService,
    public dialogRef: MatDialogRef<WhyUpgradePlanComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WhyUpgradePlanModel) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.lableName = data.lableName;
  }

  ngOnInit() {
    this.tabArr = [{svgIcon:'Self-Awareness',title:'Awareness of Yourself',activated:false},
    {svgIcon:'recommendation',title:'Awareness of Career Landscape',activated:false},
    {svgIcon:'Experience',title:'Awareness of the Right Fit',activated:false}]


    //Responsive UI
    this.breakpointObserver
    .observe([Breakpoints.Small, Breakpoints.XSmall])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isSmallDevice = true;
        this.isLargeDevice = false;
      }
    });
    this.breakpointObserver
    .observe([Breakpoints.Tablet])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isSmallDevice = true;
        this.isLargeDevice = false;
      }
    });
    this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.XLarge])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isLargeDevice = true;
        this.isSmallDevice = false;
      }
    });
  }

  
  @ViewChild('videoPlayer') videoplayer: any;
  public startedPlay:boolean = false;
  public show:boolean = false;
  pauseVideo(videoplayer)
  {
    //videoplayer.nativeElement.play();
    // this.startedPlay = true;
    // if(this.startedPlay == true)
    // {
      setTimeout(() => 
      {
        videoplayer.nativeElement.pause();
        if(videoplayer.nativeElement.paused)
        {
          this.show = !this.show;       
        } 
      }, 5000);
    // }
  }

  closebutton(videoplayer){
    this.show = !this.show; 
    videoplayer.nativeElement.play();
  }


  tabClick(index){
    console.log('index-------------', index)
    this.selectedIndex = index;
  }

  onConfirm(){
    //this._router.navigate(['/assessment']);
    if(this.data) {
      this.dialogRef.close(true)
    }
    else {
     // this.dialogRef.close(this.data.nextRoute)
    }
    
  }

  scrollToTop(event,val) {
    console.log('val---------', val)
    this.tabArr = this.tabArr.map((element,index)=>{
      
      if(index == event) {
        element.activated = val;
        }
        return element;
    });
  
    if (val){
      let elementId = `accordian_${event}`
      let el = document.getElementById(elementId);
      el.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  getStarted(){
    this.dialogRef.close();
    this.router.navigate(['/pricing/plans']);
    this.studentService.highlightLeftMenu('','parent')
  }
}


export class WhyUpgradePlanModel {

  constructor(public title: string, public message: string, public lableName: string) {
  }
}