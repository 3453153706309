import { FeatureService } from './../../../services/feature.service';
import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState
} from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import Storage from '@aws-amplify/storage';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../../services/auth.service';
import { SidenavService } from '../../services/sidenav.service';
import { StudentService } from '../../services/student.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatSidenav } from '@angular/material/sidenav';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Subscription } from 'rxjs';
import { CareerService } from '../../../career-recom/services/career.service'
import { MessageService } from 'src/app/assessments/services/message.service';
import { LocalstorageService } from 'src/app/services/localstorage.service';
import { PsychometricService } from '../../../assessments/services/psychometric.service';
import { FeedbackPopupComponent } from 'src/app/shared/components/feedback/feedback-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { CacheService } from 'src/app/assessments/services/cache.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  host: {
    "(window:click)": "onClick()"
  }
})
export class NavbarComponent implements OnInit, AfterViewInit {
  errorCount: number = 0;
  recomResponse: any = false;
  stream: any = false;
  subscription: Subscription;
  imageUrl: any = "";
  editFile: boolean = true;
  removeUpload: boolean = false;
  userProfileForm: FormGroup;
  panelOpenState: boolean;
  panelOpenStateOne: boolean;
  panelOpenStateTwo: boolean;
  currDiv: any;
  streamArr: any = [];
  public userName: any;
  public clicked: boolean = false;
  public isSmallDevice: boolean = false;
  public isLargeDevice: boolean = false;
  showCollapsedSidebar: boolean = false;
  showFirstBtnActive: boolean = false;
  showSecondBtnActive: boolean = false;
  showThirdBtnActive: boolean = false;
  showFirstTabHighlight: boolean = false;
  showSecondTabHighlight: boolean = false;
  showThirdTabHighlight: boolean = false;
  firstName: String;
  userDetails: any;
  userDetailsBasic: any;
  s3Image = '';
  activePlan = ''
  imageFile;
  hamCenter = false;
  hamPullUp: boolean = false;
  invalidFile = false;
  showHideDropdown: boolean = false;
  arrowIcon: boolean = false;
  hamburger: boolean = true;
  isActive: boolean = false;
  setDrawerClose: boolean = false;
  toolTip1 = ''; toolTip2 = ''; toolTip3 = ''; toolTip4 = ''; toolTip5 = ''; toolTip6 = ''; toolTip7 = ''; toolTip8 = '';
  @ViewChild('drawer') public drawer: MatDrawer;
  @ViewChild('drawer2') public drawer2: MatDrawer;
  @ViewChild('sidenav') sidenav: MatSidenav;
  animationVal: any;
  TeenIndex = 1;
  navbarData: any;
  navItem: any;
  isTeen: boolean = false;
  proteenLogo: string = './../../assets/svg/Proteen-Logo.svg';
  constructor(private _messageService: MessageService, private _cacheService: CacheService, private _featureService: FeatureService, private cd: ChangeDetectorRef, public fb: FormBuilder, private _authService: AuthService, private router: Router, public breakpointObserver: BreakpointObserver, private drawerService: SidenavService, private _studentService: StudentService, public careerService: CareerService,
    private _localStorage: LocalstorageService, public _psychometricService: PsychometricService, private dialog: MatDialog) {
    this.subscription = this.careerService.collapseNavbar.subscribe((res: any) => {
      this.showCollapsedSidebar = res.collapseNavbar;
    })
  }

  //CENTER HAMBURGER for student profile
  checkChild(event) {
    this.hamPullUp = false;
    //component will need to be updated if profile component class is changed
    if (event.constructor.name == 'UserProfileComponent' ||
      event.constructor.name == 'MultipleIntelligenceComponent' ||
      event.constructor.name == 'PersonalityComponent' ||
      event.constructor.name == 'AssessmentComponent' ||
      event.constructor.name == 'InterestComponent' ||
      event.constructor.name == 'InterestInstructionsComponent' ||
      event.constructor.name == 'AptitudeComponent') {
      this.hamCenter = true;
    }
    else if (event.constructor.name == 'PlansComponent' ||
      event.constructor.name == 'ReportStatusComponent' ||
      event.constructor.name == 'MainCareerLayoutComponent' ||
      event.constructor.name == 'ParentPlansComponent' ||
      event.constructor.name == 'AllCareersComponent' ||
      event.constructor.name == 'RecommendationsComponent' ||
      event.constructor.name == 'MainReportComponent' ||
      event.constructor.name == 'CareerQuizComponent' ||
      event.constructor.name == 'EndScreenComponent' ||
      event.constructor.name == 'CareerQuestionsComponent' ||
      event.constructor.name == 'DashboardComponent') {
      this.hamPullUp = true;
      this.hamCenter = true;
    }
    else {
      this.hamCenter = false;
    }
  }

  titleCase(str) {
    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
  }

  fetchPlan() {
    this._featureService.getUserPlan().subscribe((planInfo: any) => {
      planInfo = planInfo[0]
      planInfo.plan = this.titleCase(planInfo.plan)
      this.activePlan = planInfo.plan
      // if(this.activePlan.toLowerCase() == 'basic') {
      //   this._studentService.navbarData = this._studentService.navbarData.filter((element) => element.title != 'Report')
      //   this._studentService.backupNavbarData = this._studentService.backupNavbarData.filter((element) => element.title != 'Report')
      // }

    })
  }

  ngOnInit(): void {
    this.onRefreshShowNavData();
    this._messageService.onMessage().subscribe((message) => {
      if (message.msgType == 'upgradePlan') {
        this.fetchPlan()
      }
    })


    this._studentService.totalUpdatePoints.subscribe((res: any) => {
      this.animationVal = res;
      this.getStudentDetails('basic');
    })
    this._studentService.updateBasicName.subscribe((res: any) => {
      this.getStudentDetails('basic');
    })
    this.drawerService.setDrawer(this.drawer);
    this.userProfileForm = new FormGroup({
      file: new FormControl('')
    });

    this._authService.getCurrentUserObj().then((cognitoUser) => {
      sessionStorage.setItem('userSub', cognitoUser.attributes.sub);
      this._studentService.updateSub();
      this.fetchPlan()
      this.getStudentDetails('basic');
      this.userName = cognitoUser.attributes.given_name;
      this.breakpointObserver
        .observe([Breakpoints.Small, Breakpoints.XSmall])
        .subscribe((state: BreakpointState) => {
          if (state.matches) {
            this.isSmallDevice = true;
            this.isLargeDevice = false;
          }
        });
      // edited for the bug no. 1042
      this.breakpointObserver
        .observe([Breakpoints.TabletPortrait])
        .subscribe((state: BreakpointState) => {
          if (state.matches) {
            this.isSmallDevice = true;
            this.isLargeDevice = false;
          }
        });
      // added for the bugs no. 1042
      this.breakpointObserver
        .observe([Breakpoints.TabletLandscape])
        .subscribe((state: BreakpointState) => {
          if (state.matches) {
            this.isSmallDevice = false;
            this.isLargeDevice = true;
          }
        });
      // added for the blank screen issue 1042
      this.breakpointObserver
        .observe([Breakpoints.Medium])
        .subscribe((state: BreakpointState) => {
          if (state.matches) {
            this.isSmallDevice = false;
            this.isLargeDevice = true;
          }
        });
      this.breakpointObserver
        .observe([Breakpoints.Large, Breakpoints.XLarge])
        .subscribe((state: BreakpointState) => {
          if (state.matches) {
            this.isLargeDevice = true;
            this.isSmallDevice = false;
          }
        });


    }).catch(error => { });
    let userRole = environment.currentLoginUser.sessionIdInfo['custom:role'].toLowerCase();

    // console.log('this.userName ', environment.currentLoginUser.sessionIdInfo['custom:role'])
    //Configure storage for profile pic
    Storage.configure({
      AWSS3: {
        bucket: 'studentuserprofile', //REQUIRED -  Amazon S3 bucket name
        region: 'ap-south-1' //OPTIONAL -  Amazon service region
      }
    });
    // -----
    // fecthing stream api to hide and show the all streams on navbar 13042023

    // fecthing stream api to hide and show the all streams on navbar 13042023
    this._studentService.getStudentDetails('education').subscribe((response: any) => {

      if (response[0]) {
        //12062023 adding logo based on grade
        this.buildProteenLogo(response[0].class_details);
        this.isTeen = response[0].isTeen;
        console.log("line no. 227", this.isTeen);
      }

    })





  }

  getMoreInformation(): string {

    return 'Complete Level 1 by collecting 1400 points to win ProTeen goodies.\n And stay tuned for more!';// \n represent break line here
  }

  collapseAllExcept(itemIndex) {
    this._studentService.navbarData.forEach((element, index) => {
      if (itemIndex != index) {
        element.opened = false;
        if (element.children && element.children.length > 0) {
          element.children.forEach((child, index1) => {
            if (child.active == true) {
              child.active = false;
            }
            return child;
          })
        }
      }
      if (element.children && element.children.length > 0) {
        element.children.forEach((child, index1) => {
          if (child.active == true) {
            element.active = true;
          }
          return child;
        })
      }
      return element;
    });
    this._localStorage.set('sidebarData', this._studentService.navbarData);
  }


  //this function is used for submenu selection
  submenuSelect(navIndex, childIndex, link) {
    this._studentService.navbarData.map((element, index) => {
      if (navIndex == index) {
        element.active = true; //If submenu gets selected then active parents flag for change selected color
        element.children.map((child, index1) => {
          if (childIndex == index1) {
            child.active = true; //active submenu flag to change selected color
          } else {
            child.active = false;
          }
          return child;
        })
      }
      else {
        element.active = false;
        if (element.children && element.children.length > 0) {
          element.children.forEach((child, index1) => {
            if (child.active == true) {
              child.active = false;
            }
            return child;
          })
        }
      }
    })
    this._localStorage.set('sidebarData', this._studentService.navbarData);
    // this.goToLink(link)
  }

  //This function is user for single menu selection
  menuSelect() {
    this._studentService.navbarData = this._studentService.navbarData.map((element, index) => {
      element.active = false;
      element.opened = false;
      if (element.children && element.children.length > 0) {
        element.children.forEach((child, index1) => {
          if (child.active == true) {
            child.active = false;
          }
          return child;
        })
      }
      return element;
    })
    this._localStorage.set('sidebarData', this._studentService.navbarData);

  }



  onRefreshShowNavData() {
    // if(this.router.url) {
    //   var url :any = this.router.url;
    //   this._studentService.navbarData.forEach((element) => {
    //       if (element.children && element.children.length > 0) {
    //         element.children.forEach((child, index1) => {
    //           if (child.routerLink == this.router.url && child.routerLink != '/assessment') {
    //             child.active = true;
    //             element.opened = true;
    //             element.active = true;
    //           }
    //           else if(child.routerLink == '/assessment'){
    //             if(url.indexOf(child.routerLink) !== -1){
    //               child.active = true;
    //               element.opened = true;
    //               element.active = true;
    //             }
    //           }
    //           return child;
    //         })
    //       }
    //     return element;
    //   });
    // }
    //if(localStorage.getItem('sidebarData')) {
    //this._studentService.navbarData = this._localStorage.get('sidebarData');
    //}
    //else{
    let cloneData = [];
    cloneData = JSON.parse(JSON.stringify(this._studentService.backupNavbarData));
    this._studentService.navbarData = cloneData;
    //}

  }

  goToLink(link) {
    if (link === '/career/all-cluster') {
      this._messageService.sendMessage({
        msgType: link,
        msgValue: link
      })
      // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      // this.router.onSameUrlNavigation = 'reload';
      // this.router.navigate(['/career']);
    }
  }

  routerNavigateClick(link) {
    this.router.navigate([link]);
  }

  update() {
    this.getStudentDetails('basic');
  }

  @ViewChild('fileInput') el: ElementRef;
  ngAfterViewInit() {

    if (this._authService.assessmentHighlight) {
      this.showFirstTabHighlight = true;
      this.showSecondTabHighlight = false;
      this.showThirdTabHighlight = false;
      this.panelOpenState = false;
      this.panelOpenStateOne = false;
      this.panelOpenStateTwo = false;
      this.showFirstBtnActive = false;
      this.showSecondBtnActive = false;
      this.showThirdBtnActive = false;

    }
    if (this.router.url.includes('/assessment')) {
      this.panelOpenState = true;
      this.setDrawerClose = true;
    }

    this._authService.getCurrentUserObj().then(data => {
      Storage.get(data.attributes.sub).then((data: any) => {
        fetch(data).then(response => {
          if (response.status == 200) {
            this.imageUrl = data;
            this.s3Image = data;
            this._studentService.s3Image = this.s3Image
          }
        }).catch(error => {
          console.log(error)
        })
        //this.imageUrl = data;
      }).catch(error => { console.log(error) })
    });
  }

  stopPropagation(reason: string) {
    console.log('reason---------', reason)
    this.drawer.open();
  }


  close(reason: string) {
    this.drawer2.open();
    // this.sidenav.open();
  }

  getStudentDetails(section) {
    this._studentService.getStudentDetails(section).subscribe(
      data => {
        this.userDetails = data[0];
        if (!this.userDetails) {
          return;
        }
        if (section == 'basic') {
          this.userDetailsBasic = data[0];
          this._studentService.totalPoints = this.userDetailsBasic.total_earn_points;
          this.firstName = this.userDetailsBasic.first_name;
          /*if(this.animationVal == 'increment'){
            this._studentService.incrementDynamic(this.userDetailsBasic.total_earn_points-200,
              Number(this.userDetailsBasic.total_earn_points),20);
              this.animationVal = ''
          }*/
        }
      },
      err => {
        console.log(err);
        /*if(this.errorCount <= 5) {
          this.getStudentDetails(section)
          this.errorCount++;
        }*/
      }
    );
  }

  uploadFile(event) {
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);
      this.imageFile = file;
      let allowed = ['jpg', 'jpeg', 'png', 'bmp']
      if (!allowed.includes(this.getFileExtension(file.name))) {
        this.invalidFile = true;
        console.log('true')
        return;
      }
      else {
        this.invalidFile = false;
      }
      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.imageUrl = reader.result;
        this.userProfileForm.patchValue({
          file: reader.result
        });
        //console.log('file', file);
        this.editFile = false;
        this.removeUpload = true;
      };
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }

  getFileExtension(filename) {
    // get file extension
    const extension = filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;
    return extension;
  }

  logout() {
    this._cacheService.fetchCacheData('student-Hobbies').subscribe((apiResponse: any) => {
      let checkData = JSON.parse(apiResponse.data);
      console.log('apiResponse==================================', apiResponse)
      if (checkData === null) {
        this._cacheService.postCacheData('1', 'student-Hobbies').subscribe((apiResponse) => {
          console.log('apiResponse--------', apiResponse)
          this.router.navigate(['/proteen/logout'])

        }, error => { console.log(error) })
      } else {
        this.router.navigate(['/proteen/logout'])

      }
    })

    //if (window.innerWidth >= 768) {
    //}
    /*else
    this._authService.signOut()
    .then(() => this._router.navigate(['login']))*/
  }

  Clicked() {
    this.clicked = true;
  }



  collapsableSidebar() {
    $(".customized-drawer").removeClass("fullWidthDrawer");
    $(".customized-drawer").addClass("iconsDrawer");

    this.showCollapsedSidebar = !this.showCollapsedSidebar;
    if (this.showCollapsedSidebar) {
      this.toolTip1 = 'Home';
      this.toolTip2 = 'Profile';
      this.toolTip3 = 'Assessment';
      this.toolTip4 = 'Careers';
      this.toolTip5 = 'Connections';
      this.toolTip6 = 'Settings';
      this.toolTip7 = 'Logout';
      this.toolTip8 = 'Feedback';

    }
    else {
      this.toolTip1 = '';
      this.toolTip2 = '';
      this.toolTip3 = '';
      this.toolTip4 = '';
      this.toolTip5 = '';
      this.toolTip6 = '';
      this.toolTip7 = '';
      this.toolTip8 = 'Feedback';

    }
  }


  closePanel() {
    this._authService.assessmentHighlight = false;
    this.showFirstTabHighlight = false;
    this.showSecondTabHighlight = false;
    this.showThirdTabHighlight = false;
    this.panelOpenState = false;
    this.panelOpenStateOne = false;
    this.panelOpenStateTwo = false;
  }

  closeLastTwoPanel() {
    this.showFirstBtnActive = false;
    this.showSecondBtnActive = false;
    this._authService.assessmentHighlight = true;
    this.showFirstTabHighlight = true;
    this.showThirdTabHighlight = false;
    this.panelOpenStateOne = false;
    this.showSecondTabHighlight = false;
    this.panelOpenStateTwo = false;
    this.showThirdBtnActive = false;
  }

  closeOtherPanel() {
    if (this._authService.assessmentHighlight) {
      this.showSecondTabHighlight = false;
    }
    else {
      this.showSecondTabHighlight = true;
    }
    this.showFirstBtnActive = false;
    this.showSecondBtnActive = false;
    this.showThirdBtnActive = false;
    this._authService.assessmentHighlight = false;
    this.showFirstTabHighlight = false;
    this.showThirdTabHighlight = false;
    this.panelOpenState = false;
    this.panelOpenStateTwo = false;
  }

  closeFirstTwoPanel() {
    this.showSecondBtnActive = false;
    this.showFirstBtnActive = false;
    this._authService.assessmentHighlight = false;
    this.showFirstTabHighlight = false;
    this.showSecondTabHighlight = false;
    this.showThirdTabHighlight = true;
    this.panelOpenState = false;
    this.panelOpenStateOne = false;
    this.showThirdBtnActive = false;
  }

  activeIconAssessment() {
    this.showFirstTabHighlight = true;
    this._authService.assessmentHighlight = true;
    this.showSecondTabHighlight = false;
    this.showThirdTabHighlight = false;
    this.showFirstBtnActive = false;
    this.showSecondBtnActive = false;
    this.showThirdBtnActive = false;
  }

  activeIconCareer() {
    if (this._authService.assessmentHighlight) {
      this.showSecondTabHighlight = false;
    }
    else {
      this.showSecondTabHighlight = true;
    }
    this._authService.assessmentHighlight = false;
    this.showFirstTabHighlight = false;
    this.showThirdTabHighlight = false;
    this.showFirstBtnActive = false;
    this.showSecondBtnActive = false;
    this.showThirdBtnActive = false;
  }

  activeIconSettings() {
    this.showThirdTabHighlight = true;
    this.showSecondTabHighlight = false;
    this._authService.assessmentHighlight = false;
    this.showFirstTabHighlight = false;
    this.showThirdTabHighlight = false;
    this.showFirstBtnActive = false;
    this.showSecondBtnActive = false;
    this.showThirdBtnActive = false;
  }

  public toggleFirstPanel() {
    this.panelOpenState = true;
  }

  showDropdown($event) {

    $event.stopPropagation();
    this.showHideDropdown = !this.showHideDropdown;
  }

  onClick() {
    this.showHideDropdown = false;
  }

  enableCollapsed() {
    $(".customized-drawer").addClass("iconsDrawer");
    $(".customized-drawer").removeClass("fullWidthDrawer");
  }

  showDashboard() {
    this.router.navigateByUrl('/student/dashboard');
  }

  showProfile() {
    this.router.navigateByUrl('/student/profile');


  }

  showAssessment() {
    this.router.navigateByUrl('/assessment');
  }

  showFirstActive() {
    this.showFirstTabHighlight = false;
    this._authService.assessmentHighlight = false;
    this.showFirstBtnActive = true;
    this.showSecondBtnActive = false;
    this.showThirdBtnActive = false;
  }

  showSecondActive() {
    this._authService.assessmentHighlight = false;
    this.showFirstTabHighlight = false;
    this.showFirstBtnActive = false;
    this.showSecondBtnActive = true;
    this.showThirdBtnActive = false;
  }

  showThirdActive() {
    if (this._authService.assessmentHighlight) {
      this.showThirdBtnActive = false;
    }
    else {
      this.showThirdBtnActive = true;
    }
    this.showFirstTabHighlight = false;
    this._authService.assessmentHighlight = false;
    this.showFirstBtnActive = false;
    this.showSecondBtnActive = false;
  }

  upgradeClick() {
    this.router.navigate(['/pricing/plans']);
    this._studentService.highlightLeftMenu('', 'parent')
  }

  openDialog(): void {

    const dialogRef = this.dialog.open(FeedbackPopupComponent, {
      width: this.isLargeDevice ? "470px" : "354px",
      height: this.isLargeDevice ? "375px" : "360",
      panelClass: 'modalboxForFeed'
    });

  }

  private buildProteenLogo(classDetails) {
    this.proteenLogo = './../../assets/svg/Proteen-Logo.svg';
    if (this.isSmallDevice) {
      this.proteenLogo = './../../assets/svg/Proteen-Logo-Mobile.svg';
    }
    let oClassDetails: any = {};
    if (typeof classDetails == 'string') {
      oClassDetails = JSON.parse(classDetails);
    } else if (typeof classDetails == 'object') {
      oClassDetails = classDetails;
    }
    if (oClassDetails.grade) {
      let grade = oClassDetails.grade;
      grade = grade.trim().toUpperCase();
      switch (grade) {
        case 'CLASS 8':
          this.proteenLogo = './../../assets/svg/grade/Class-8.svg';
          break;
        case 'CLASS 9':
          this.proteenLogo = './../../assets/svg/grade/Class-9.svg';
          break;
        case 'CLASS 10':
          this.proteenLogo = './../../assets/svg/grade/Class-10.svg';
          break;
        case 'CLASS 11':
          this.proteenLogo = './../../assets/svg/grade/Class-11.svg';
          break;
        case 'CLASS 12':
          this.proteenLogo = './../../assets/svg/grade/Class-12.svg';
          break;
        case 'GRADUATION – 1ST YEAR':
          this.proteenLogo = './../../assets/svg/grade/Graduation-1st.svg';
          break;
        case 'GRADUATION – 2ND YEAR':
          this.proteenLogo = './../../assets/svg/grade/Graduation-2nd.svg';
          break;
        case 'GRADUATION – 3RD YEAR':
          this.proteenLogo = './../../assets/svg/grade/Graduation-3rd.svg';
          break;
        case 'GRADUATION – 4TH YEAR':
          this.proteenLogo = './../../assets/svg/grade/Graduation-4th.svg';
          break;
        case 'POST-GRADUATION – 1ST YEAR':
          this.proteenLogo = './../../assets/svg/grade/Post-Graduation-1st.svg';
          break;
        case 'POST-GRADUATION – 2ND YEAR':
          this.proteenLogo = './../../assets/svg/grade/Post-Graduation-2nd.svg';
          break;
        case 'DIPLOMA':
          this.proteenLogo = './../../assets/svg/grade/For-Diploma.svg';
          break;

        default:
          break;
      }
    }

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }



}
