import { LocalstorageService } from './../../../services/localstorage.service';
import { FeedbackPopupComponent } from 'src/app/shared/components/feedback/feedback-popup.component';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Table } from 'primeng/table';
import { BulkeditDialogComponent, BulkeditDialogModel } from 'src/app/admin/components/popup/add-dialog/bulkedit-dialog.component';
import { ReorderPopupComponent } from 'src/app/admin/components/popup/reorder-popup/reorder-popup.component';
import { SendMailComponent } from 'src/app/admin/components/popup/send-mail/send-mail.component';
import { DashboardModel } from 'src/app/admin/model/dashboard.model';
import { AdminService, MOBILEVIEW } from 'src/app/admin/services/admin.service';
import { MessageService } from 'src/app/assessments/services/message.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/student/popups/confirm-dialog/confirm-dialog.component';
import { environment } from 'src/environments/environment';
import { AdminFeedbackPopupComponent } from 'src/app/admin/components/popup/admin-feedback-popup/admin-feedback-popup.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { Paginator } from 'primeng/paginator';


interface bulkActionList {
  name: string,
  code: string
}
export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD-MM-YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-simple-table',
  templateUrl: './simple-table.component.html',
  styleUrls: ['./simple-table.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },]
})
export class SimpleTableComponent implements OnInit {
  showNoRecordsFound: boolean = false;
  @Input() tableData;
  @Input() tableTitle;
  @Input() tableDataForCollege;
  @Input() columnHeader;
  @Input() showImportButton: boolean = true;
  cloneColumns: any;
  @ViewChild('dt')
  private dt: Table;
  @Input() tableFor;
  @Input() searchData;
  @Input() filterFlag: boolean;
  @Input() filterDisplay;
  @Output() editCompleteEvent = new EventEmitter();
  @Output() editCancelEvent = new EventEmitter();
  @Output() sortEvent = new EventEmitter();
  @Output() deleteEvent = new EventEmitter();
  @Output() clickFeedback = new EventEmitter();
  @Output() viewClick = new EventEmitter();
  @Output() filterEvent = new EventEmitter();
  @Output() removeFilterEvent = new EventEmitter();
  @Output() clearFilter = new EventEmitter();
  @Output() exportEvent = new EventEmitter();
  @ViewChild('paginator', { static: true }) paginator: Paginator;

  selectedColumns = []
  dropdownSource = []
  cols: any[];
  seletedBulkAction = null;
  bulkAction: bulkActionList[];
  objectKeys = Object.keys;
  dataSource;
  tempRow;
  exportDate: any = ""
  minFilterYear = '2019'
  maxFilterYear = new Date().getFullYear()
  selectedData = []
  bulddeleteflag: boolean = false;
  deleteResult: any;
  dashboardModel: DashboardModel;
  minDate: any = parseInt(environment.minYear) - 15;
  maxDate;
  dropdownList: any;
  totalRecords: number;
  selectedTableRow: any;
  urlImages: any;
  editTdStyle: boolean = false;
  deletedIdsList: any[];
  deletedIndex: any[];
  statuses: any = [];
  editDataids: any[];
  tableTotalCount: number;
  showGrayDropdownIcon: boolean = true;
  pagenumercount: number = 0;
  rowPerPage: number = 10;
  bMobileView: boolean;

  constructor(private _adminService: AdminService,
    private _localStorage: LocalstorageService,
    private router: Router, private _messageService: MessageService,
    public dialog: MatDialog, private spinner: NgxSpinnerService) {
    this._adminService.mobileViewBreakPointObs.subscribe(
      response => {
        this.bMobileView = response == MOBILEVIEW ? true : false;
      }
    );
    const urltype = environment.apiType
    this.dashboardModel = new DashboardModel();
    this.statuses = [
      { label: 'Active', value: 'Active' },
      { label: 'InActive', value: 'InActive' }
    ]

    this.dropdownList = [
      { item_id: 1, iconOrange: '../../../../assets/svg/Admin/export.svg', iconGray: '../../../../assets/svg/Admin/exportGray.svg', item_text: 'Export' },

    ];
  }

  onViewClick(event) {
    this.viewClick.emit(event)
  }

  removeFilter(dt, filterRemoved) {
    this.removeFilterEvent.emit({ table: dt, filter: filterRemoved });
  }

  showPopup(event) {
    this.clickFeedback.emit(event)    //call popup
  }



  ngOnInit(): void {
    this.bulkAction = [
      { name: 'Export', code: '1' },
      // {name: 'Export All', code: '2'},
      // {name: 'Delete', code: '2'},

    ];
    this._adminService.simpleTableData.subscribe(
      response => {
        this.tableTotalCount = response;
      });
    if (this.tableFor == 'app-personalHobbies') {
      this.urlImages = 'https://proteen-hobbies-logo.s3.ap-south-1.amazonaws.com/dev'
    } else if (this.tableFor == 'app-personalAspirations') {
      this.urlImages = 'https://proteen-aspirations-logo.s3.ap-south-1.amazonaws.com/dev'
    } else if (this.tableFor == 'app-extraCurricular') {
      this.urlImages = 'https://proteen-extra-curricular-activities-logo.s3.ap-south-1.amazonaws.com/dev'
    } else if (this.tableFor == 'app-school') {
      this.bulddeleteflag = true;
    }

    this.minDate = moment(this.minDate.toString()).format('YYYY-MM-DD')
    this.maxDate = moment().format('YYYY-MM-DD');

    this.totalRecords = 100;

  }

  onClearFilter(dt) {
    this.clearFilter.emit(dt)
  }

  paginate(val) {
    this.pagenumercount = val.page;
    this.rowPerPage = val.rows;
    this._adminService.sendSimpleMessage(val)
  }

  ngAfterContentInit() {

    //Deep clone
    this.cloneColumns = JSON.parse(JSON.stringify(this.columnHeader));
    this.cloneColumns.forEach((element) => {
      if (!element.hide) {
        this.selectedColumns.push(element);
      }
    })
    this.dropdownSource = JSON.parse(JSON.stringify(this.columnHeader));
    this.dropdownSource = this.dropdownSource.filter(element => element.dropdownDisplay)
    //this.dataSource = new MatTableDataSource(this.tableData);
    //this.dataSource.paginator = this.paginator;
    //this.dataSource.sort = this.sort;
  }

  exportCSV(dt) {
    this.spinner.show()
    //let clonedColumns = JSON.parse(JSON.stringify(dt.columns));
    let exportHeader = dt.columns.filter(element => !(element.hide || !element.dropdownDisplay));
    dt.columns = exportHeader;
    if (this.selectedData.length > 0) {
      dt.exportCSV({ selectionOnly: true });
    }
    else {
      dt.exportCSV();
    }

    dt.columns = this.columnHeader
    this.spinner.hide()
  }

  resetFilters(dt) {
    dt.columns = this.cloneColumns;
    this.columnHeader = this.cloneColumns
    this.cloneColumns = JSON.parse(JSON.stringify(this.columnHeader))
    this.selectedColumns = []
    this.cloneColumns.forEach((element) => {
      if (!element.hide) {
        this.selectedColumns.push(element);
      }
    })
    this.dropdownSource = JSON.parse(JSON.stringify(this.columnHeader));
    this.dropdownSource = this.dropdownSource.filter(element => element.dropdownDisplay)
  }

  toggleValue(eventData) {
    let foundIndex = this.columnHeader.findIndex((element) => element.field == eventData.itemValue.field)
    this.columnHeader[foundIndex].hide = !this.columnHeader[foundIndex].hide;
  }


  sendMail() {
    const dialogRef = this.dialog.open(SendMailComponent, {
      width: "60%",
      height: "auto",
      panelClass: "mailDialog",
      data: this.selectedData,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(dialogResult => {

      if (dialogResult) {
        this.reloadCurrentRoute();
      } else {
        this.reloadCurrentRoute();
      }

    });
  }

  // sendMail() {
  //   const dialogRef = this.dialog.open(SendMailComponent, {
  //     width: "60%",
  //     height: "auto",
  //     panelClass: "mailDialog",
  //     data: this.selectedData
  //   });
  //   dialogRef.afterClosed().subscribe(dialogResult => {
  //     dialogRef.disableClose = true;
  //     if (dialogResult) {

  //     }else{
  //       this.reloadCurrentRoute();
  //     }

  //   });
  // }



  ngAfterContentChecked() {
    //this.dataSource.sort =  this.sort;
  }


  submitBulk() {
    this.selectChangeBulk(this.seletedBulkAction.name);
    console.log('this.seletedEditandDeleted------------', this.seletedBulkAction)
  }

  selectChangeBulk(val) {
    if (val == 'Export') {
      console.log('Export -----------------------', val)
      this.getExportDataForTable();
    } else {
      if (this.selectedData.length >= 1) {
        this.editDataids = [];
        switch (val) {
          case 'Send Mail':
            this.sendMail();
            break;
          case 'Edit':
            this.selectedData.forEach(element => {
              if (this.tableFor == 'admin-student') {
                this.editDataids.push(element.uuid)
              } else {
                this.editDataids.push(element.id)
              }
            });
            this.bulkEditDialog(this.editDataids);
            break;
          case 'Delete':
            this.deletedIteam();
            break;

          default:
            break;
        }
      } else {
        this.seletedBulkAction = null;

        this._adminService.showMessageForAdmin('No Row Selected!', 'error-snackbar')
        this.reloadCurrentRoute();
        this.selectedData = []
        let tableStateKey = `${this.tableFor}-filters`;
        let tableState = this._localStorage.get(tableStateKey);
        tableState.selection = []
        this._localStorage.set(tableStateKey, tableState)
      }
    }


  }

  reloadCurrentRoute() {

    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);

  }
  getExportDataForTable() {
    this.exportEvent.emit(this.selectedData.map((element) => element.uuid))
    this.seletedBulkAction = null;
    this.selectedData = [];
  }

  deletedIteam() {
    console.log('this.selectedData', this.selectedData)

    this.deletedIdsList = [];
    this.deletedIndex = [];
    this.selectedData.forEach(element => {
      if (this.tableFor == 'admin-student') {
        this.deletedIdsList.push(element.uuid)

      } else {
        this.deletedIdsList.push(element.id)

      }
    });

    this.commanDelete('0', this.deletedIndex, this.deletedIdsList);

  }


  onRowEditInit(rowData) {
    this.editTdStyle = true;
    if (this.tableFor == 'admin-student') {
      this.columnHeader.forEach((element) => {
        if (element.field == 'date_of_birth') {
          if (rowData[element.field] != null) {
            let theDate = new Date(rowData[element.field]);
            rowData[element.field] = theDate;
          }
        }
      });
    }
    this.tempRow = { ...rowData };
  }

  filterClick(column) {
    console.log('column------------------------', column)
    this.tableData.forEach((element) => {
      if (column.dataType == 'date') {
        if (column.field != 'renewal_date') {
          if (element[column.field] != '0000-00-00 00:00:00' && element[column.field] != '' && element[column.field] != null) {
            let theDate = new Date(element[column.field]);
            element[column.field] = theDate;
          }
        } else if (column.field == 'renewal_date') {
          if (element.plan_details.renewal_date && element.plan_details.renewal_date != '0000-00-00 00:00:00' && element.plan_details.renewal_date != '' && element[column.field] != null) {
            let theDate = new Date(element.plan_details.renewal_date);
            element.plan_details.renewal_date = theDate;
          }
        }
      }
    });
  }
  onRowEditSave(rowData) {
    this.editTdStyle = false;
    //console.log(this.isFieldEditable(rowData.field));
    /*if(this.tableFor == 'admin-student' && this.isFieldEditable(rowData.field)) {
      this.editCompleteEvent.emit(rowData);
    }
    if(this.tableFor == 'admin-parent' && this.isFieldEditable(rowData.field)) {
      this.editCompleteEvent.emit(rowData);
    }*/
    if (this.tableFor == 'admin-student') {
      this.columnHeader.forEach((element) => {
        if (element.field == 'date_of_birth') {
          let theDate = new Date(rowData[element.field]);
          let finalDate = theDate.getFullYear() + "-" + (theDate.getMonth() + 1) + "-" + theDate.getDate();
          rowData[element.field] = finalDate;
        }
      });
    }
    this.editCompleteEvent.emit(rowData);
  }

  onRowEditCancel(rowData, index) {
    this.editTdStyle = false;
    this.tableData[index] = this.tempRow;
  }

  deleteRow(rowData, index) {
    if (this.tableFor == 'app-college' || this.tableFor == 'app-board' || this.tableFor == 'app-school' || this.tableFor == 'app-universities' || this.tableFor == 'app-competitiveExams' || this.tableFor == 'app-occupations' || this.tableFor == 'app-parentEducation' || this.tableFor == 'app-fieldAchievement' || this.tableFor == 'app-levelAchievement' || this.tableFor == 'app-personalLanguages' || this.tableFor == 'app-otherDisabilities' || this.tableFor == 'app-healthConcerns' || this.tableFor == 'app-knowProteen' || this.tableFor == 'app-reasonsJoin') {
      // if(confirm("Do you want to delete this row?")) {
      //   this.deleteEvent.emit({"id":rowData['id']});
      // }
      this.commanDelete(rowData['id'], index, '1');
      //  if(confirm("Do you want to delete this row?")) {
      //   this.deleteEvent.emit({"id":rowData['id']});
      // }
    } else if (this.tableFor == 'admin-student') {
      this.commanDelete(rowData['uuid'], index, '1');

      // this.commanUuidDelete(rowData);
    }
    else {
      if (confirm("Do you want to delete this row?")) {
        this.deleteEvent.emit({ "uuid": rowData['uuid'], "index": index });
      }
    }

  }

  applyFilter(event) {
    this.filterEvent.emit(event)
    console.log(this.paginator)
    this.goToFirstpage()

  }

  goToFirstpage() {
    let eventObj = { preventDefault() { } }
    setTimeout(() => this.paginator.changePageToFirst(eventObj));
  }

  isFieldEditable(value) {
    let indexNeeded = Object.keys(this.columnHeader).find(key => this.columnHeader[key]["field"] === value);
    return this.columnHeader[indexNeeded].allowEdit;
  }


  commanDelete(rowData, index, multipleDelete) {
    const message = `Are you sure you want to delete this row?`;
    const dialogData = new ConfirmDialogModel("Confirm", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      //maxWidth: "400px",
      data: dialogData,
      disableClose: true

    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.deleteResult = dialogResult;
        this.deleteEvent.emit({ "id": rowData, 'index': index, multipleDelete });
        // this._facebookService.facebookUnlink();
      } else {
        this.reloadCurrentRoute();
      }
    });
  }

  updateData(event) {
    this.sortEvent.emit(event)
  }

  commanUuidDelete(rowData) {
    const message = `Are you sure you want to delete this row?`;
    const dialogData = new ConfirmDialogModel("Confirm", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: dialogData,
      disableClose: true

    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      dialogRef.disableClose = true;

      console.log('dialogResult==========================', dialogResult)
      if (dialogResult) {
        this.deleteResult = dialogResult;
        this.deleteEvent.emit({ "uuid": rowData['uuid'] });
      } else {
      }

    });
  }


  bulkEditDialog(rowData) {
    const message = `Are you sure you want to Edit Bulk this row?`;
    const dialogData = new BulkeditDialogModel("Mass Edit", message, rowData);
    const dialogRef = this.dialog.open(BulkeditDialogComponent, {

      width: '50%',
      data: dialogData,
      panelClass: 'mass-dialog-container',
      disableClose: true

    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      dialogRef.disableClose = true;

      if (dialogResult) {
        this.seletedBulkAction = null;
        this.deleteResult = dialogResult;
        this.deleteEvent.emit({ "uuid": rowData['uuid'] });
      } else {
        this.reloadCurrentRoute();
      }

    });
  }


}
